
.rdw-editor-wrapper{
    /* background-color: #e8e8e83b; */
    border: 1px solid rgba(34,36,38,.15);	
    border-radius: .28571429rem;
    color: rgba(0,0,0,.87);
    outline: 0;   
    padding: .78571429em 1em;
    text-align: left;
    background-color: #fff;
    margin-top: 4px;
}




.ui.search .prompt {
    border-radius: 0rem;
}


