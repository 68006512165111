.Bag-icon {
  display: flex;
  flex-wrap: wrap;
}
.bag {
  cursor: pointer;
}

.NumberBook {
  position: absolute;
  background-color: orangered;
  color: #fff;
  padding: 4px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  z-index: 99;
}
.text1 span {
  color: red;
}

.complete {
  background-color: #7ac142;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}

.borrow {
  background-color: #eed12b;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}
.request {
  background-color: #1a7199a8;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}
.dispose {
  /* background-color: #eed12b; */
  color: #f30909;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}
