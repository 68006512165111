.App {
  text-align: center;
}

*:focus {
  outline: none;
}

body {
  font-size: 0.875em !important;
}

.b-grid-cell.b-group-title {
  font-weight: bold !important;
  color: #ff9800 !important;
}
.FIXHEADER {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  max-height: 45px;
  z-index: 200;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/* diagonal-line */
.diagonal-line {
  position: relative;
  z-index: 1;
  height: 1px;
  background-color: #c1c1c1;
  -webkit-transform: rotate(135deg);
  transform: rotate(155deg);
}

/* upload file bt */
.choose_file {
  position: relative;
  display: inline-block;
  /* font: normal 14px Myriad Pro, Verdana, Geneva, sans-serif;
    color: #7f7f7f;
    margin-top: 2px;
    background: white */
}
.choose_file input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(255, 255, 255); /* Black fallback color */
  background-color: rgba(255, 255, 255, 0.95); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: all 0.3s ease-in-out;
  transform: rotate3d(0, 0, 0, 0deg);
  /* transition: 0.2s; */
  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/*  ======== input ======== */
.ui.active.visible.fluid.selection.scrolling.dropdown.time
  .menu.transition.visible {
  max-height: 20vh !important;
}
.ui.form input[type="number"] {
  text-align: center !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.ui.form input[type]:focus,
.ui.form textarea:focus {
  border-color: #7ac142 !important;
  box-shadow: inset 0 2px 4px 0 hsla(0, 0%, 0%, 0.08) !important;
}
.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown {
  border-color: #7ac142 !important;
}

@media only screen and (max-width: 767px) {
  .field {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}

/*  ======== button ======== */
/* .ui.basic.button{ */
/* box-shadow: none  !important; */
/* font-weight: 600 !important; */
/* } */

/*  ======== accordion  ======== */
.ui.accordion .title:not(.ui) {
  padding: 0 !important;
}
/* .ui.accordion:not(.styled) .title~.content:not(.ui):last-child{
  padding-top: 0px;
} */
.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),
.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
  padding-left: 22px !important;
}
/*  ======== card  ======== */
.ui.fluid.card {
  border: 0;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.15) !important;
}
.ui.card {
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.15) !important;
}
.ui.raised.card,
.ui.four.doubling.cards > .card {
  box-shadow: 0 1px 6px 0 hsla(0, 0%, 0%, 0.2) !important;
}
.ui.card .meta {
  color: rgb(115, 115, 115) !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ui.card > .content > .description,
.ui.cards > .card > .content > .description {
  color: hsl(0, 0%, 29%) !important;
}
.ui.card > .content,
.ui.cards > .card > .content {
  color: hsl(0, 0%, 45%) !important;
}

/*  ======== label / display ======== */
.contentSegment {
  margin: 10px !important;
}
.statusLabel {
  letter-spacing: 1px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.ui.form .field > label {
  text-align: left;
  color: hsl(0, 0%, 29%) !important;
}
.ui.checkbox input.hidden + label {
  font-size: 0.92857143em;
}
.ui.toggle.checkbox input:focus:checked ~ label {
  font-size: 0.92857143em;
  font-weight: 700;
}

.ui.dropdown .menu {
  z-index: 100 !important;
}

/*  ======== menu ======== */
/* .ui.visible.left.sidebar ~ .pusher {
  width: 80%;
} */
.ui.floating.dropdown > .menu {
  width: 100%;
}
.disableMenu {
  /* color:#fff !important; */
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.68) !important;
  /* background-color:#f3f4f6 !important; */
  background-color: rgba(0, 0, 0, 0.07) !important;

  margin-bottom: 1px !important;
}
.disableActiveMenu {
  /* color:#fff !important; */
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.68) !important;
  /* background-color:#f3f4f6 !important; */
  background-color: #dc6c00 !important;

  margin-bottom: 1px !important;
}
.subMenu {
  color: rgba(0, 0, 0, 0.68) !important;
  font-weight: bold !important;

  padding-left: 25px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 1px !important;
}
.accordionMenu {
  color: rgba(0, 0, 0, 0.68) !important;
  font-weight: bold !important;

  padding-left: 40px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 1px !important;
}
.ui.menu .item {
  /* color: hsl(0,0%,13%) !important; */
  /* margin-left: 10px  !important; */
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
/* .ui.secondary.menu .item {
  margin: 0 !important;
} */
.ui.secondary.menu .active.item {
  box-shadow: none;
  background: #7ac142 !important;
  color: #fff !important;
  border-radius: 0.28571429rem;
}

/* .ui.secondary.menu a.item:hover {
  color: #7AC142 !important;
  background: #fff !important;
} */

.ui.secondary.brown.menu .active.item {
  box-shadow: none;
  background: #80561b !important;
  color: #fff !important;
  border-radius: 0.28571429rem;
}

.ui.secondary.brown.menu a.item:hover {
  color: #80561b !important;
  background: #fff !important;
}

.ui.secondary.pointing.menu {
  overflow-y: hidden;
  overflow-x: auto;
}

/*  ======== font ======== */
p,
h1,
h2,
h3,
h4,
h5,
.ui,
.ui.header,
.ui.card > .content > .header,
.ui.cards > .card > .content > .header,
.ui.accordion .title:not(.ui),
.ui.list > .item .header,
.content {
  font-family: "Athiti", sans-serif !important;
}
.ui.button,
input[type],
.ui.form input[type],
.ui.form textarea,
.ui.checkbox input.hidden + label {
  font-family: "Athiti", sans-serif !important;
}

/*  FF margin modal  */
@-moz-document url-prefix() {
  .ui.modal .scrolling.content {
    margin-bottom: 20px;
  }
}

/* ========= icon ========== */
i.bordered.icon {
  border-radius: 5px;
}

/* ========= calendar ========== */
/* .ant-row-flex-middle{
  display: none  !important;
} */
.scheduler {
  margin: 0 !important;
}
/* .rbc-day-slot .rbc-event-label{
    display: none  !important;
} */

.header-reservation {
  color: gray !important;
  margin-bottom: 4px !important;
}

.header3-text,
.rbc-header,
.header2-text,
.rbc-label {
  color: rgba(0, 0, 0, 0.68) !important;
  font-weight: bold !important;
  font-size: 90% !important;
}
.rbc-day-slot .rbc-time-slot {
  border-top: none !important;
}
table.scheduler-bg-table td {
  border-right: none !important;
}
table.scheduler-bg-table tr td:nth-child(2n + 1) {
  border-left: 1px solid #e9e9e9 !important;
}
.react-calendar-timeline .rct-sidebar-header {
  background: #9e9e9e !important;
}
.react-calendar-timeline .rct-header .rct-label-group {
  background: #9e9e9e !important;
}
.rct-item {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.rbc-today {
  background-color: #ededed8c !important;
}
.DayPicker {
  width: 100%;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #fde3c9 !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #7ac142 !important;
  color: #ffffff !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #dc6c00 !important;
}
.DayPicker-Day--today {
  color: #9c27b0 !important;
}
/* ========= table ========== */
/* .rthfc .rt-tbody .rt-tr-group {
  border-bottom: solid 1px black !important;
} */
.rthfc.-striped .rt-tr .rt-td {
  padding: 3px;
}
.header-table,
.rthfc .rt-th {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* background: black !important; */
  color: black !important;
}
.footer-table {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #e2e2e2 !important;
  color: black !important;
  font-weight: 600;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.rthfc .-headerGroups .rt-th {
  padding: 10px;
  font-weight: bold;
  font-family: "Athiti", sans-serif !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-family: "Athiti", sans-serif !important;
}

.ReactTable {
  font-size: 95%;
  background-color: #fff;
}

.ReactTable .-loading {
  z-index: -1;
}
.ReactTable .-loading.-active {
  z-index: 2;
}
.ReactTable .rt-thead.-header {
  background-color: #80561b;
  color: #fff;
  box-shadow: 0 0.5px 5px 0 rgba(0, 0, 0, 0.15) !important;
}
.my-course-column-header-group.rt-resizable-header.rt-th {
  background-color: #8cdbff !important;
  text-align: left;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  max-height: 20vh;
}

.my-check-column-header-group.rt-resizable-header.rt-th {
  background-color: #41b8ef !important;
  text-align: left;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  max-height: 20vh;
}
.my-flight-column-header-group.rt-resizable-header.rt-th {
  background-color: #ffeb3b !important;
}

.my-sim-column-header-group.rt-resizable-header.rt-th {
  background-color: #cddc39 !important;
}

.ReactTable {
  font-family: "Athiti", sans-serif !important;
}
.bold {
  color: rgba(0, 0, 0, 0.68);
  font-weight: bold;
}

/*  color sort => header*/
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -2px 0 0 #eb282e;
}
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 2px 0 0 #00a950;
}

iframe {
  border: none;
}

/*  shadow => header*/

.ui.accordion tbody.ui.content.active {
  display: table-row-group;
  background-color: #00a950;
}

.ui.accordion .title:not(.ui) {
  padding: 0px;
  margin: 0px;
}
.ui.accordion .title:not(.ui).active {
  padding: 0px;
  margin: 0px;
  /* background-color: #dc6c00; */
}

.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
  padding-left: 0 !important;
}

/*  progressbar */
.center.aligned.column.progressbar:only-child {
  border-radius: 30px 30px 30px 30px !important;
}
.center.aligned.column.progressbar:last-child {
  border-radius: 0px 30px 30px 0px;
}
.center.aligned.column.progressbar:first-child {
  border-radius: 30px 0px 0px 30px;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-3-14 15:3:42
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.shake-bottom {
  animation: shake-bottom 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    both;
}
@keyframes shake-bottom {
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  -webkit-overflow-scrolling: touch;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}
@media print {
  .FIXHEADER,
  #myFIXHEADER,
  #myNav,
  #myMenu,
  #myHeadMenu,
  #mySideMenu,
  #myLayout,
  #myProfile {
    display: none;
  }

  .contentSegment {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    height: 1000px;
    /* transform: scaleX(1.2) scaleY(1.2); */
    /* margin-to: -300px; */
  }
  #page-to-print,
  #page-to-print * {
    visibility: visible;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    page-break-before: avoid;
    page-break-after: avoid;
    /* transform: scaleX(1.2) scaleY(1.2); */
    /* margin-to: -300px; */
  }
  @page {
    size: A4;
    margin: 25mm;
    /* transform: scaleX(2) scaleY(1.5); */
  }
  /* #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  } */
}
